import React, {useRef, useState} from 'react'
import { Button, Grid, TextField, Typography } from '@mui/material';
import emailjs from '@emailjs/browser';

export function Contact({setOpenContact, lg}){
const form = useRef();

const Submit = () => {
    emailjs.sendForm('service_f2vrzyd', 'template_mpjcnjb', form.current, 'dfZNzoy_4wd9C-Grt')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    setOpenContact(false)
  }

return(
  <div style={{padding: '10px'}}>
    <form ref={form}>
    <Grid  container rowSpacing={3} columnSpacing={2} alignItems='center' justifyContent='center' style={{fontFamily:'Urbanist'}}>
      <Grid item xs={12} sm={12} md={12}>
        {lg=='fr'?
          <Typography variant='h6' style={{textAlign:'center'}}>Contactez-nous!</Typography>
        :
          <Typography variant='h6' style={{textAlign:'center'}}>Contact us!</Typography>
        }
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <TextField required sx={{width: '100%'}} variant="filled" placeholder={lg=='fr'? 'Prénom':'Firstname'} name="prenom" />
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <TextField required sx={{width: '100%'}} variant="filled" placeholder={lg=='fr'?'Nom':'Lastname'} name="nom" />
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <TextField sx={{width: '100%'}} variant="filled" type='tel' placeholder={lg=='fr'?'Numéro de téléphone':'Phone number'} name="phone" />
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <TextField required sx={{width: '100%'}} type="email" variant="filled" placeholder={lg=='fr'?'Adresse courrielle':'Email address'} name="email" />
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <TextField sx={{width: '100%'}} variant="filled" placeholder={lg=='fr'?'Entreprise':'Company'} name="entreprise" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField required multiline rows={6} sx={{width: '100%'}} variant="filled" placeholder={lg=='fr'?'Votre message':'Your message'} name="message" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} container spacing={3} justifyContent='center' alignItems='center'>
        <Grid item xs={6} sm={6} md={6}>
          {lg=='fr'?
            <Button variant='outlined' color='secondary' sx={{float:'right'}} onClick={e => setOpenContact(false)}>Annuler</Button>
          :
            <Button variant='outlined' color='secondary' sx={{float:'right'}} onClick={e => setOpenContact(false)}>Cancel</Button>
          }
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          {lg=='fr'?
            <Button variant='contained' onClick={Submit}>Envoyer</Button>
          :
            <Button variant='contained' onClick={Submit}>Send</Button>
          }
        </Grid>
    </Grid>
    </Grid></form>
  </div>
  );}

  

  