import { Box, Grid, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import theme from './Theme'
import Paper from '@mui/material/Paper';
import Camille from './images/Camille.png'
import Clemence from './images/Clemence.png'
import CircleIcon from '@mui/icons-material/Circle';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
}));
const Box1 = styled(Paper)(() => ({
    borderRadius : '100px 0px 100px 80px / 90px;',
  color: 'black',
  backgroundColor: theme.palette.secondary.main,
  fontFamily: 'Urbanist',
  textAlign: 'center',
  width:'90%',
  paddingTop:'10px',
  paddingBottom:'10px'
}))
const Box2 = styled(Paper)(() => ({
  borderRadius : '0px 100px 100px 80px / 90px;',
  color: 'black',
  backgroundColor: theme.palette.secondary.main,
  fontFamily: 'Urbanist',
  textAlign: 'center',
  width:'90%',
  paddingTop:'10px',
  paddingBottom:'10px'
}))

const equipe =(lg) => (
      <div id={lg == 'fr' ? 'Equipe':'Team'}>
        <Grid container columns={20} rowSpacing={2} justifyContent='start' justifyItems='end' alignItems='center' style={{paddingTop:'1em'}}>
            <Grid item xs={2} sm={2} md={2}/>
            <Grid item xs={9} sm={6} md={3}>
                <Typography color='primary' variant='h3' style={{fontFamily: 'Expletus Sans'}}>
                    Equipe.
                </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={5}>
                <BorderLinearProgress variant="determinate" value={100} style={{marginTop:'1.9em'}}/>
            </Grid>
            <Grid item xs={3} sm={6} md={10}></Grid>
            
            <Grid item xs={1} sm={3} md={3}></Grid>
            <Grid item xs={18} sm={14} md={5} container columns={20} rowSpacing={1} justifyContent='center' alignItems="flex-start" style={{fontFamily: 'Urbanist', color:'black'}}>
                <Grid item xs={20} sm={20} md={20} container justifyContent='center' style={{marginTop:'20px'}}>
                    <img src={Clemence} width='269px' height='264px'></img>
                </Grid>
                <Grid item xs={20} sm={20} md={20} container justifyContent='center'>
                    <Typography variant='h5' align='center'><b>CLÉMENCE</b><br/>Jean-Louis dit Montout</Typography>
                </Grid>
                <Grid item xs={20} sm={20} md={20} container justifyContent='center'>
                    {lg == 'fr'?
                        <Box1><Typography variant='h6'><b>Directrice Générale</b></Typography></Box1>
                    :
                        <Box1><Typography variant='h6'><b>Chief Executive Officer</b></Typography></Box1>
                    }
                </Grid>
                <Grid item xs={20} sm={20} md={20}>
                    <List>
                        <ListItem>
                        <ListItemIcon><CircleIcon sx={{ fontSize: 5 }}/></ListItemIcon>
                        {lg == 'fr'?
                            <Typography variant='b1'>Finissante à la maîtrise à l'ÉTS</Typography>
                        :
                            <Typography variant='b1'>Master's graduate at ÉTS</Typography>
                        }
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><CircleIcon sx={{ fontSize: 5 }}/></ListItemIcon>
                            {lg == 'fr'?
                                <Typography variant='b1'>Co-diplômation avec l’École Centrale d’Électronique - Paris</Typography>
                            :
                                <Typography variant='b1'>Co-graduation with École Centrale d'Électronique - Paris</Typography>
                            }
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><CircleIcon sx={{ fontSize: 5 }}/></ListItemIcon>
                            {lg == 'fr'? 
                                <Typography variant='b1'>Spécialisée en Technologies de l’Information</Typography>
                            :
                                <Typography variant='b1'>Specialized in Information Technology</Typography>
                            }
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Grid item xs={1} sm={2} md={0}/>

            <Grid item xs={1} sm={3} md={2}></Grid>
            <Grid item xs={18} sm={14} md={5} container columns={20} rowSpacing={1} justifyContent='center' alignItems="flex-start" style={{fontFamily: 'Urbanist', color:'black'}}>
                <Grid item xs={20} sm={20} md={20} container justifyContent='center'>
                    <img src={Camille}></img>
                </Grid>
                <Grid item xs={20} sm={20} md={20} container justifyContent='center' alignItems="flex-start">
                    <Typography variant='h5' align='center'><b>CAMILLE</b><br/>Jean-Louis dit Montout</Typography>
                </Grid>
                <Grid item xs={20} sm={20} md={20} container justifyContent='center'>
                    {lg == 'fr' ?
                        <Box2><Typography variant='h6'><b>Cheffe des Opérations</b></Typography></Box2>
                    :
                        <Box2><Typography variant='h6'><b>Chief Operating Officer</b></Typography></Box2>
                    }
                </Grid>
                <Grid item xs={20} sm={20} md={20}>
                    <List>
                    <ListItem>
                        <ListItemIcon><CircleIcon sx={{ fontSize: 5 }}/></ListItemIcon>
                        {lg == 'fr'?
                            <Typography variant='b1'>Diplômée de l’IESEG School of Management en 2020</Typography>
                        :
                            <Typography variant='b1'>Graduated from IESEG School of Management in 2020</Typography>
                        }
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><CircleIcon sx={{ fontSize: 5 }}/></ListItemIcon>
                        {lg == 'fr'?
                            <Typography variant='b1'>Consultante en transformation digitale pour des entreprises dans le Retail et l'Industrie</Typography>
                        :
                            <Typography variant='b1'>Consultant in digital transformation for companies in Retail and Industry</Typography>
                        }
                    </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Grid>
      </div>
    );

  export default equipe;