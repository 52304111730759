import { Box, Chip, Grid, Slider, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled, makeStyles } from '@mui/material/styles';
import theme from './Theme'
import { Paper, Button } from '@mui/material'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import React, {useEffect, useState} from 'react'
import StepContent from '@mui/material/StepContent';

const innovation =(lg) => (
    <div id="Innovation">
    <Grid container columns={20} rowSpacing={2} justifyContent='start' justifyItems='end' alignItems='center' style={{paddingTop:'1em'}}>
        <Grid item xs={18} sm={18} md={18}>
            <Typography color='primary' variant='h3' style={{fontFamily: 'Expletus Sans', textAlign:'right'}}>
                Innovation.
            </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2}></Grid>

        <Grid item xs={3} sm={2} md={3}></Grid>
        <Grid item xs={14} sm={15} md={14} style={{fontFamily: 'Urbanist', textAlign:'right'}}>
            {lg == 'fr'?
            <Typography color='primary' variant='h6' style={{color:'black'}}>
                Axolotl est une innovation dans le milieu des <b>technologies médicales</b>.
            </Typography>
            :
            <Typography color='primary' variant='h6' style={{color:'black'}}>
                Axolotl is an innovation in the field of <b>medical technology </b>.
            </Typography>
            }
            <br/>
        </Grid>
        <Grid item xs={1} sm={1} md={1} container justifyContent='center'>
            <Divider light orientation='vertical' sx={{ bgcolor: (theme) => theme.palette.primary.main, height: '150px', marginLeft:'8px', borderRadius: 5, width: 5, }}/>
        </Grid>
        <Grid item xs={0} sm={0} md={2}></Grid>

        <Grid item xs={2} sm={0} md={2}></Grid>
        <Grid item xs={20} sm={20} md={16} direction='row' container alignItems='center' style={{fontFamily: 'Urbanist', gridAutoRows: '1fr',}}>
            <Grid item xs={1} sm={1} md={2}></Grid>
            <Grid item xs={9} sm={5} md={4} style={{height: '160px'}} container alignItems='center'>
                {lg == 'fr' ?
                    <Typography variant='h6' align='right'> <b>1ère et unique solution</b> au problème de la <b>reconnaissance</b> précoce de l'<b>hypoxémie</b> pédiatrique selon les directives actuelles <a href="https://journals.lww.com/pccmjournal/abstract/2023/02001/methodology_of_the_second_pediatric_acute_lung.6.aspx" target="_blank" style={{textDecoration:'none', color:'#009788'}}><b>PALICC</b></a></Typography>
                :
                    <Typography variant='h6' align='right'> <b>First and only solution</b> to the problem of early <b>recognition</b> of pediatric <b>hypoxemia</b> according to current <a href="https://journals.lww.com/pccmjournal/abstract/2023/02001/methodology_of_the_second_pediatric_acute_lung.6.aspx" target="_blank" style={{textDecoration:'none', color:'#009788'}}><b>PALICC</b></a> guidelines</Typography>
                }
            </Grid>
            <Divider orientation='vertical' flexItem>
                <Chip label='1' color="secondary" sx={{borderRadius :'100px 0px / 90px', marginLeft:'4px'}}/>
            </Divider>
            <Grid item xs={0} sm={15} md={7}/>
            
            <Grid item xs={1} sm={6} md={6}/>
            <Divider orientation='vertical' flexItem >
                <Chip label='2' color="secondary" sx={{borderRadius :'0px 100px / 90px'}}/>
            </Divider>
            <Grid item xs={9} sm={5} md={4} style={{height: '180px'}} container alignItems='center'>
                {lg == 'fr'? 
                    <Typography variant='h6' align='left'>Nouvelle méthode pour <b>synthétiser les informations</b> sur les <b>tendances</b> passées des indices mesurées, et observer la tendance sur plusieurs heures/jours</Typography>
                :
                    <Typography variant='h6' align='left'>New method for <b>synthesizing information</b> on past trends of measured values, with the possibility of observing the <b>trend</b> of indices over several hours/days </Typography>
                 }
            </Grid>
            <Grid item xs={0} sm={0} md={1}></Grid>

            <Grid item xs={1} sm={1} md={2}></Grid>
            <Grid item xs={9} sm={5} md={4} style={{height: '200px'}} container alignItems='center'>
            {lg == 'fr'? 
                <Typography variant='h6' align='right'>Solution présentant des dashboards sur les <b>lignes directrices</b> et les <b>anomalies</b> permettant une compréhension universelle (aux médecins, infirmiers et patients) de l’<b>état du patient</b></Typography>
            :
                <Typography variant='h6' align='right'>Solution featuring <b>dashboards</b> and illustrations of <b>guidelines</b> and <b>anomalies</b>, enabling universal understanding (by doctors, nurses and patients) of the <b>patient's condition</b></Typography>
            }
            
            </Grid>
            <Divider orientation='vertical' flexItem>
                <Chip label='3' color="secondary" sx={{borderRadius :'100px 0px / 90px', marginLeft:'1px'}}/>
            </Divider>
            <Grid item xs={0} sm={10} md={7}/>

            <Grid item xs={1} sm={6} md={6}/>
            <Divider orientation='vertical' flexItem>
                <Chip label='4' color="secondary" sx={{borderRadius :'0px 100px / 90px'}}/>
            </Divider>
            <Grid item xs={9} sm={4} md={4} style={{height: '160px'}} container alignItems='center'>
            {lg == 'fr'? 
                <Typography variant='h6' align='left'>Solution pouvant être utilisée au <b>chevet du patient</b> tout au long de son séjour à l'hôpital</Typography>
            :
                <Typography variant='h6' align='left'>Solution can be used at the <b>patient's bedside</b> throughout the hospital stay</Typography>
            }
            </Grid> 

        </Grid>   
        <Grid item xs={20} sm={20} md={20}><br/></Grid>

        <Grid item xs={2} sm={2} md={4}></Grid>
        <Grid item xs={9} sm={10} md={7} container justifyContent='center'>
        {lg == 'fr'?
        <Typography align='center' variant='h5' style={{fontFamily: 'Urbanist',fontStyle: 'italic'}}><b>Axolotl a gagné le 2ème prix de la meilleure présentation en 2023 lors 
            de la conférence sur l’Intelligence Artificielle et l’aide à la décision clinique 
            en soins pédiatriques aigus lors du 90ème congrès de l’<a href="https://www.acfas.ca/" target="_blank" style={{textDecoration:'none', color:'#F2825E'}}>Acfas</a>.</b>
        </Typography> 
        :
            <Typography align='center' variant='h5' style={{fontFamily: 'Urbanist',fontStyle: 'italic'}}><b>Axolotl won 2nd prize for best
                presentation in 2023 at the Artificial Intelligence and Clinical Decision Support in Acute Pediatric Care conference at the
                 90th <a href="https://www.acfas.ca/" target="_blank" style={{textDecoration:'none', color:'#F2825E'}}>Acfas</a> Congress.</b>
            </Typography>
        }
        </Grid>
    </Grid>
    </div>
); 

  export default innovation;
