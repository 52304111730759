import './Carrousel.css'
import { Box, Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import React, {useEffect, useState} from 'react'
import { styled } from '@mui/material/styles';
import theme from './Theme'
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import car1 from "./images/car1.jpg"
import car2 from "./images/car2.jpg"
import car3 from "./images/car3.jpg"
import car4 from "./images/car4.jpg"
import Slider from "react-slick";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


function Item(props)
{
    const Carte = styled(Paper)(() => ({
        backgroundImage: 'url('+ props.item.img +')',
        width: '290px',
        height: '360px',
        lineHeight: '360px',
        textAlign: 'center',
        color: 'white',
        borderRadius: '10%',
        backgroundSize:'cover',
        backgroundPosition:'center',
        fontFamily: 'Urbanist',
        margin:'auto',
      }));
    
    return (
        <Carte>
            <Typography style={{lineHeight: '1.5',  display: 'inline-block', verticalAlign:'middle', 
            backgroundColor:'rgba(0,0,0,0.7)', margin: '1em'}}>
                <b>{props.item.description}</b></Typography>
        </Carte>
    )

} 


export function Carroussel({lg}){

  var items = [
    {
        img: car1,
        description: lg == 'fr' ? 
        "Exposer l'état passé et actuel des indices permettant l'évaluation de l'hypoxémie"
        :
        "Show the past and present status of indices for assessing hypoxemia"
    },
    {
        img: car2,
        description: lg == 'fr' ? 
        "Présenter les marqueurs de gravité surveillés pendant la ventilation mécanique"
        :
        "Present gravity markers monitored during mechanical ventilation"
    },
    {
        img: car3,
        description: lg == 'fr' ? 
        "Illustrer la tendance des marqueurs et mettre en avant les valeurs anormales"
        :
        "Illustrate marker trends and highlight abnormal values"
    },
    {
        img: car4,
        description: lg == 'fr' ? 
        "Permet d’avoir une vision claire de l'évolution de l'état du patient afin d’administrer les soins"
        :
        "Provides a clear understanding of how the patient's condition is evolving, so that care can be administered accordingly"
    }
]
const [imageIndex, setImageIndex] = useState(0);

const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <ArrowForwardIosIcon />
      </div>
    );
  };

const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <ArrowBackIosIcon />
      </div>
    );
  };

  const settings = {
    infinite: true,
    speed: 200,
    autoplay: true,
    slidesToShow: window.innerWidth >= 636 ? 3:1,
    centerMode: true,
    centerPadding: 10,
    dots:true,  
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />, 
    beforeChange: (current, next) => setImageIndex(next),
  };
  return(
    <div className='sol'>
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" /> 
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <Slider {...settings}>
            <div className={ imageIndex === 0 ? "slide activeSlide" : "slide"}>
                <Item item={items[0]} />
            </div>
            <div className={ imageIndex === 1 ? "slide activeSlide" : "slide"}>
                <Item item={items[1]} />
            </div>
            <div className={ imageIndex === 2 ? "slide activeSlide" : "slide"}>
                <Item item={items[2]} />
            </div><div className={ imageIndex === 3 ? "slide activeSlide" : "slide"}>
                <Item item={items[3]} />
            </div>
        </Slider>
    </div>   
  )
};