import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#009788',
    },
    secondary: {
      main: '#F2825E',
    },
    divider:'#F2825E'
  },
  typography: {
    fontFamily: [
      'Urbanist 300',
      'Expletus Sans'
    ].join(','),
  },
});

export default theme;