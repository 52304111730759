import { Box, Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import React, {useEffect, useState} from 'react'
import { Carroussel } from './Carrousel';

export const solution = (lg) => (
    <div id='Solution'>
        <Grid container columns={20} rowSpacing={2} justifyContent='start' justifyItems='end' alignItems='flex-start' style={{paddingTop:'1em'}}>
            <Grid item xs={18} sm={18} md={18}>
                <Typography color='primary' variant='h3' style={{fontFamily: 'Expletus Sans', textAlign:'right'}}>
                    Solution.
                </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2}></Grid>

            <Grid item xs={2} sm={2} md={3}></Grid>
            <Grid item xs={15} sm={15} md={14} style={{fontFamily: 'Urbanist', textAlign:'right'}}>
                {lg == 'fr'?
                    <Typography color='primary' variant='h6' style={{color:'black'}}>
                        Axolotl est une <b>interface connectée</b> à une base de données relationnelle qui stocke les données de surveillance continue en temps réel de tous les patients admis à l'unité de soins intensifs pédiatriques.
                    </Typography>
                :
                    <Typography color='primary' variant='h6' style={{color:'black'}}>
                        Axolotl is an interface <b>connected to a relational database</b> that stores <b>real-time continuous</b> monitoring data for <b>all patients admitted</b> to the pediatric intensive care unit.                     
                    </Typography>
                }
                <br/>
                {lg == 'fr'?
                    <Typography color='primary' variant='h6' style={{color:'black'}}>
                        L'interface trilingue (anglais, français et espagnol) est <b>actualisée</b> en permanence et n'est accessible que sur le <b>réseau local</b> avec une <b>authentification</b> appropriée.
                    </Typography>
                :
                    <Typography color='primary' variant='h6' style={{color:'black'}}>
                        The trilingual interface (English, French and Spanish) is <b>constantly updated</b> and is only accessible on the local network with appropriate authentication.                    
                    </Typography>
                }       
            </Grid>
            <Grid item xs={1} sm={1} md={1} container justifyContent='center'>
                <Divider light orientation='vertical' sx={{ bgcolor: (theme) => theme.palette.primary.main, color:(theme) => theme.palette.primary.main,  height: '150px', marginLeft:'8px', borderRadius: 5, width: 5, }}/>
            </Grid>

            <Grid item xs={2} sm={0} md={2}></Grid>
            <Grid item xs={16} sm={16} md={16} >  
                <Carroussel lg={lg}/>
            </Grid>
        </Grid>
    </div>
  );

  export default solution;
