import {Grid, Typography } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import tech from './images/tech.png'
import techEN from './images/techEN.png'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
}));

const technologie =(lg) => (
      <div id={lg == 'fr' ? 'Technologie':'Technology'}>
        <Grid container columns={20} rowSpacing={1} justifyContent='start' justifyItems='end' alignItems='center' style={{paddingTop:'1em'}}>
        <Grid item xs={2} sm={2} md={2}></Grid>
          <Grid item xs={12} sm={9} md={5}>
          {lg == 'fr' ?
            <Typography color='primary' variant='h3' style={{fontFamily: 'Expletus Sans'}}>
                Technologie.
            </Typography>
            :
            <Typography color='primary' variant='h3' style={{fontFamily: 'Expletus Sans'}}>
                Technology.
            </Typography>
            }
          </Grid>
          <Grid item xs={4} sm={4} md={5}>
            <BorderLinearProgress variant="determinate" value={100} style={{marginTop:'1.9em'}}/>
          </Grid>
          <Grid item xs={10} sm={0} md={10}></Grid>          
          <Grid item xs={20} sm={20} md={20} container justifyContent='center'>
            {lg == 'fr'?
              <img src={tech} width='70%' height='70%'></img>
              :
              <img src={techEN} width='70%' height='70%'></img>
            }
          </Grid>          
        </Grid>
      </div>
    );

  export default technologie;
