import { Grid, Typography } from '@mui/material';
import homepage from "./images/Homepage.png";

const intro = (lg) => window.innerWidth < 635 ?
    (<div>
      <Grid container rowSpacing={3} justifyContent='center' alignItems='center'>
        <Grid item xs={10}>
          {lg == 'fr' ?
          (<Typography color='secondary' variant='h5' align='center' style={{fontFamily: 'Urbanist'}}>
             Système d'<b>aide</b> à la <b>décision</b> mettant en avant l'<b>évolution</b> des données de patients admis en <b>soins intensifs pédiatriques</b>
          </Typography>)
          :
          (<Typography color='secondary' variant='h5' align='center' style={{fontFamily: 'Urbanist'}}>
             <b>Decision support system</b> highlighting the <b>evolution</b> of <b>patients’ data</b> admitted to pediatric <b>intensive care</b>.
          </Typography>)
          }
        </Grid>
        <Grid item xs={18} container justifyContent='center'>
          <img src={homepage} height="60%" width="60%" alt="Homepage" />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </div>
    )
    :
    (<div>
        <Grid container columns={20} rowSpacing={1} justifyContent='center' alignItems='center'>
        <Grid item sm={1} md={3}></Grid>
          <Grid item sm={11} md={7}>
          {lg == 'fr' ?
            (<Typography color='secondary' align={window.innerWidth >= 636 ?'left':'center'} variant={window.innerWidth >= 636 ?'h3':'h6'} style={{fontFamily: 'Urbanist'}}>
                Système d'<b>aide</b> à la <b>décision</b> mettant en avant l'<b>évolution</b> des données de patients admis en <b>soins intensifs pédiatriques</b>
            </Typography>)
            :
            (<Typography color='secondary' align={window.innerWidth >= 636 ?'left':'center'} variant={window.innerWidth >= 636 ?'h3':'h6'} style={{fontFamily: 'Urbanist'}}>
             <b>Decision support system</b> highlighting the <b>evolution</b> of <b>patients’ data</b> admitted to pediatric <b>intensive care</b>.
            </Typography>)
            }
          </Grid>
          <Grid item sm={1} md={1}/>
          <Grid item sm={6} md={6}>
            <img src={homepage} height="90%" width="90%" alt="Homepage" />
          </Grid>
          <Grid item sm={2} md={2}></Grid>
        </Grid>
      </div>);

  export default intro;
