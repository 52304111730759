import { Box, Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import theme from './Theme'
import Paper from '@mui/material/Paper';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
}));
const Box1 = styled(Paper)(() => ({
  borderRadius : '0px 100px 100px 80px / 90px;',
  color: 'white',
  backgroundColor: theme.palette.secondary.main,
  fontFamily: 'Urbanist',
  textAlign: 'center',
  paddingBottom:'2%',
  paddingTop:'2%'
}))
const Box2 = styled(Paper)(() => ({
  borderRadius : '100px 0px / 90px;',
  color: 'white',
  backgroundColor: theme.palette.secondary.main,
  fontFamily: 'Urbanist',
  textAlign: 'center',
  paddingBottom:'10%',
  paddingTop:'10%'
}))
const Box3 = styled(Paper)(() => ({
  borderRadius : '0px 100px / 90px;',
  color: 'white',
  backgroundColor: theme.palette.secondary.main,
  fontFamily: 'Urbanist',
  textAlign: 'center',
  paddingBottom:'10%',
  paddingTop:'10%'
}))
const besoin =(lg) => (
      <div id={lg == 'fr' ? 'Besoin':'Needs'}>
        <Grid container columns={20} rowSpacing={2} justifyContent='start' justifyItems='end' alignItems='center' style={{paddingTop:'1em'}}>
        <Grid item xs={2} sm={2} md={2}></Grid>
        {lg =='fr' ?
          <Grid item xs={8} sm={6} md={3}>
            <Typography color='primary' variant='h3' style={{fontFamily: 'Expletus Sans'}}>
                 Besoin.
            </Typography>
          </Grid>
          :
          <Grid item xs={8} sm={6} md={3}>
            <Typography color='primary' variant='h3' style={{fontFamily: 'Expletus Sans'}}>
                 Needs.
            </Typography>
          </Grid>
          }
          <Grid item xs={6} sm={5} md={5}>
            <BorderLinearProgress variant="determinate" value={100} style={{marginTop:'1.9em'}}/>
          </Grid>
          <Grid item xs={4} sm={6} md={10}></Grid>
          
          <Grid item xs={1} sm={2} md={2}></Grid>
          <Grid item xs={18} sm={17} md={7} columns={20} container columnSpacing={1} rowSpacing={1} alignItems='center'>
            <Grid item xs={20} sm={20} md={20}>
              {lg == 'fr'? 
              <Box1>
                <Typography variant='h5'><b>HYPOXÉMIE</b></Typography>
                <Typography variant='h6'>Diminution anormale <br/>de la quantité d’oxygène <br/>dans le sang</Typography>
              </Box1>
              :
                <Box1>
                  <Typography variant='h5'><b>HYPOXEMIA</b></Typography>
                  <Typography variant='h6'>Abnormal decrease of the oxygen <br/>amount in the blood</Typography>
                </Box1>
              }
            </Grid>
            <Grid item xs={10} sm={10} md={10}>
              <Box2>
                <Typography variant='h4'><b>50%</b></Typography>
                {lg == 'fr' ? 
                  <Typography variant={window.innerWidth < 635 ?'subtitle1':'h6'}>des patients <br/> admis en sont <br/>atteints</Typography>
                :
                  <Typography variant={window.innerWidth < 635 ?'subtitle1':'h6'}>of admitted patients <br/>are affected</Typography>
                }
              </Box2>
            </Grid>
            <Grid item xs={10} sm={10} md={10} >
              <Box3>
                <Typography variant='h4'><b>25%</b></Typography>
                {lg == 'fr' ? 
                  <Typography variant={window.innerWidth < 635 ?'subtitle1':'h6'}>de taux de <br/>mortalité pour les <br/>formes sévères</Typography>
                :
                  <Typography variant={window.innerWidth < 635 ?'subtitle1':'h6'}>of mortality rate for severe forms</Typography>
                }
              </Box3>
            </Grid>

          </Grid>
          <Grid item xs={1} sm={0} md={0}/>
          <Grid item xs={1} sm={2} md={1}></Grid>
          <Grid item xs={18} sm={17} md={8} style={{fontFamily: 'Urbanist', color:'black'}}>
            {lg == 'fr' ? 
              <Typography color='primary' variant='h6' style={{color:'black'}}>
                Aujourd’hui, il est difficile de suivre l’évolution en temps réel des indices permettant l’évaluation de l'<b>hypoxémie</b>.<br/>
              </Typography>
            :
              <Typography color='primary' variant='h6' style={{color:'black'}}>
                It is currently difficult to assess hypoxemia in real time, a <b>frequent</b> and <b>life-threatening</b> condition in <b>children admitted</b> to pediatric intensive care units.<br/>               
              </Typography>
            }
            <br/>
            {lg == 'fr' ?
              <Typography color='primary' variant='h6' style={{color:'black'}}>
                Le but de la solution est de présenter aux cliniciens la <b>tendance des principaux indices d’évaluation de l'état d'oxygénation </b> 
                en mettant en évidence les principaux marqueurs de gravité surveillés pendant la ventilation invasive et non invasive afin de 
                <b> les aider à améliorer les soins</b>.<br/>
              </Typography>
            :
              <Typography color='primary' variant='h6' style={{color:'black'}}>
                The aim of this project is to develop a <b>solution</b> highlighting the <b>main severity markers</b> monitored during invasive and non-invasive ventilation. <br/>
              </Typography>
            }
            <br/>
            {lg == 'fr' ?
              <Typography color='primary' variant='h6' style={{color:'black'}}>
                Ce diagnostic est malheureusement souvent réalisé tardivement, <b>pouvant entraîner une hausse de la mortalité</b> chez les jeunes patients.
              </Typography>
            :
              <Typography color='primary' variant='h6' style={{color:'black'}}>
                The aim is to provide clinicians with trends in the main indices used to <b>assess oxygenation status</b>, to help them <b>improve care</b>. 
                <br/><br/>
                Unfortunately, this <b>diagnosis</b> is often <b>delayed</b>, which can lead to increased patient mortality.              
              </Typography>
            }
          </Grid>
        </Grid>
      </div>
    );

  export default besoin;
