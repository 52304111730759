import { Button, Grid, Typography } from '@mui/material';
import logo from "./images/Logo.png";
import { text } from './Text';
import { Contact } from './Contact';
import React, {useState} from 'react'
import theme from './Theme';
import linkedin from "./images/linkedin.png";
import insta from "./images/instagram.png";
import yt from "./images/youtube.png";


const footer =(lg)=>(
    <div style={{backgroundColor:theme.palette.secondary.light, color:'white', fontFamily: 'Urbanist'}}>
      <Grid container columns={20} rowSpacing={1} alignItems='center'>
      <Grid item xs={1} sm={1} md={1}></Grid>
        <Grid item xs={10} sm={5} md={4} style={{paddingTop:'1em'}}>
          <img align='center' src={logo} height="108" width="152" alt="Logo"/>
          {lg=='fr' ?
            <Typography variant='b2' color='primary'><br/>Startup accélérée par le <a href="https://centech.co/" target="_blank" style={{textDecoration:'none', color:'#009788'}}><b>Centech</b></a>.</Typography>
          : 
            <Typography variant='b2' color='primary'><br/>Startup accelerated by <a href="https://centech.co/" target="_blank" style={{textDecoration:'none', color:'#009788'}}><b>Centech</b></a>.</Typography>
          }
        </Grid>
        <Grid item xs={5} sm={4} md={6} columnSpacing={1} rowSpacing={1} container justifyContent='center' alignItems='center'>
            <Grid item xs={4} sm={3} md={1} style={{marginRight:'10px'}}>
              <a href='https://www.linkedin.com/company/axolotlsolution/about/' target="_blank">
                <img align='center' src={linkedin} height="50" width="50" alt="Linkedin"/>
              </a>
            </Grid>
            <Grid item xs={4} sm={3} md={1}>
              <a href='https://youtu.be/3HTyHcNUtqs' target="_blank">
                <img align='center' src={yt} height="50" width="50" alt="Youtube"/>
              </a>
            </Grid>
        </Grid>

        <Grid item xs={1} sm={0} md={0} />

        <Grid item xs={18} sm={5} md={4} >
            <Typography variant='b1'>1-22 rue Ontario E, Montréal (Québec) H2X3W2</Typography>
            <Typography variant='b1'><br/>+1 (438) 738 7397</Typography>
            <Typography variant='body2'> <a href="mailto:contact@axolotlsolution.com" style={{color:'white'}}>contact@axolotlsolution.com</a></Typography> 
        </Grid>
        <Grid item xs={20} sm={20} md={20} container justifyContent='center'>
            <Typography variant='b1' align='center'>Copyright © Axolotl 2023</Typography>
        </Grid>
      </Grid>
    </div>
  );

  export default footer;
