import React, {useEffect, useState} from 'react'
import { AppBar, Button, CssBaseline, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import intro from './Intro';
import header from './Header'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import besoin from './Besoin';
import { styled } from '@mui/material/styles';
import solution from './Solution';
import technologie from './Technologie';
import innovation from './Innovation';
import equipe from './Equipe';
import footer from './Footer';
import { Anchor} from 'antd';
import { Contact } from './Contact';
import logo from "./images/Logo.png";
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import en from './images/en.png'
import fr from './images/fr.png'


const { Link } = Anchor;
function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

function App(props) {
  const [lg, setLg] = useState('fr')
  const listePages = [lg == 'fr' ? 'Besoin':'Needs', 'Solution', lg == 'fr' ? 'Technologie':'Technology','Innovation',lg == 'fr' ? 'Equipe':'Team']
  const [value, setValue] = useState('0')
  const handleLangue = (event) => {
    lg == 'fr' ? setLg('en'): setLg('fr');
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [openContact, setOpenContact] = useState(false);
  const handleOpenContact= () => { 
      setOpenContact(!openContact);
    };
  

  return (
    <div className="App" style={{background: 'linear-gradient(#FFFFFF, #F1F8F1)'}}>
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link href="https://fonts.googleapis.com/css2?family=Expletus+Sans&family=Urbanist:wght@300&display=swap" rel="stylesheet"/>
      <Grid container columns={20} rowSpacing={3}>
        <Grid item xs={1} sm={1} md={1}></Grid>
        <Grid item xs={5} sm={5} md={5} style={{marginTop:'1%'}}>
          <img src={logo} height={window.innerWidth < 635 ?"72":"108"} width={window.innerWidth < 635 ?'101':"152"} alt="Logo"/>
        </Grid>
        <Grid item xs={9} sm={10} md={10}></Grid>
        <Grid item xs={3} sm={2} md={2} justifyContent='flex-end' rowSpacing={0} container>
            {lg=='fr' ?
              <Button onClick={handleLangue}>
                <img src={en} alt='EN' width={window.innerWidth < 635 ?'25px':'35px'} height={window.innerWidth < 635 ?'15px':'20px'}></img>
              </Button>
            :
              <Button onClick={handleLangue}>
                <img src={fr} alt='FR' width={window.innerWidth < 635 ?'25px':'35px'} height={window.innerWidth < 635 ?'15px':'20px'}></img>
              </Button>
            }
          <Grid item xs={9} sm={10} md={10}></Grid>
              <Button onClick={handleOpenContact}>
                <Typography variant={window.innerWidth < 635 ?'h6':'h5'} style={{textAlign:'end', fontFamily: 'Urbanist'}}>Contact</Typography>
              </Button>
                <Dialog open={openContact} onClose={handleOpenContact}>
                <DialogContent sx={{backgroundColor:'white'}}>
                  <Contact setOpenContact={setOpenContact} lg={lg}/>
                </DialogContent>
              </Dialog>
        </Grid>
        <Grid item xs={20} sm={20} md={20}>
          {intro(lg)}
        </Grid>
         <Grid item xs={20} sm={20} md={20} container alignItems='center' justifyContent='center'>
          <AppBar id="back-to-top-anchor" direction="horizontal" position="static" color='transparent' style={{boxShadow:'none', alignContent:'center', textAlign:'center', display: 'block', margin:'0 auto',}}>
            {listePages.map((page, i) =>{
                    return( <Button href={'#'+page} key={i} value={i} onClick={handleChange} style={{padding:"0 1em", boxShadow:'none', fontSize: 20, fontFamily:'Urbanist', color:'black',':active':{textDecoration: 'unterline'}}}>
                      {page}</Button>)

            })}
          </AppBar>
            
        </Grid> 
        <Grid item xs={20} sm={20} md={20}>
          {besoin(lg)}
        </Grid>
        <Grid item xs={20} sm={20} md={20}>
          {solution(lg)} 
        </Grid>
        <Grid item xs={20} sm={20} md={20}>
          {technologie(lg)}
        </Grid>
        <Grid item xs={20} sm={20} md={20}>
          {innovation(lg)}
        </Grid>
        <Grid item xs={20} sm={20} md={20}>
          {equipe(lg)}
        </Grid>
        <Grid item xs={20} sm={20} md={20}>
          {footer(lg)}
        </Grid>
      </Grid>
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  );
}

export default App;
